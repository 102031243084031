import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getIntWorkflowList = (context, payload) => {
  return http.getApi(URL_UTILITY.getIntWorkflowListUrl, payload);
};

const getPaymentSeriesList = (context, bankAccountId) => {
  return http.getApi(
    URL_UTILITY.getPaymentSeriesUrl + '/' + bankAccountId + '/payment-series'
  );
};

const addEditSourcingApprovalForHeader = (context, payload) => {
  return http.postApi(URL_UTILITY.getNfaGridListUrl, payload);
};

const addEditSourcingApprovalForDetails = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getNfaGridListUrl + '/' + payload.nfa_hdr_id,
    payload
  );
};

const addEditPaymentSeriesList = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getPaymentSeriesUrl +
      '/' +
      payload.bank_acc_id +
      '/payment-series',
    payload
  );
};

const getSkipSeriesList = (context, paymentSeriesId) => {
  return http.getApi(
    URL_UTILITY.getSkipSeriesUrl +
      '/' +
      paymentSeriesId +
      '/skip-payment-series'
  );
};

const addEditSkipSeriesList = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getSkipSeriesUrl +
      '/' +
      payload.payment_series_id +
      '/skip-payment-series',
    payload
  );
};

const getWfTabsDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getIntWorkflowListUrl +
      '/' +
      payload.tabType +
      '/' +
      payload.wfHeaderId
  );
};
const addEditIntWorkflow = (context, payload) => {
  return http.postApi(URL_UTILITY.getIntWorkflowListUrl, payload);
};
const getWaiverData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getIntWorkflowListUrl +
      '/waivers/' +
      payload.nfaHdrId +
      '/' +
      payload.nfaPrjId
  );
};

const getCodeComb = (context, payload) => {
  return http.getApi(URL_UTILITY.getHrmsCodeCombinationUrl, payload);
};
const getHistory = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getIntWorkflowHistoryUrl +
      '/' +
      payload.historyType +
      '/' +
      payload.eventId +
      '/' +
      payload.record_id
  );
};

const getEventId = (context, menuId) => {
  return http.getApi(
    URL_UTILITY.getEventIdByMenuIdUrl.replace('{menuId}', menuId)
  );
};
const processWorkflow = (context, payload) => {
  return http.postApi(URL_UTILITY.getProcessWorkflowUrl, payload);
};
const getApprovalList = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getApprovalListUrl
      .replace('{recordId}', payload.nfaHdrId)
      .replace('{menuId}', payload.eventId) +
      '?approvalStatus=' +
      payload.approvalStatus
  );
};
const getSendForApprovalList = (context, payload) => {
  return http.getApi(URL_UTILITY.getWorkFlowApprovalListUrl, payload);
};
const getWrkflowApproval = (context, payload) => {
  return http.postApi(URL_UTILITY.getWrkflowApprovalUrl, payload);
};
const getWfDetailsById = (context, nfaHdrId) => {
  return http.getApi(URL_UTILITY.getIntWorkflowListUrl + '/' + nfaHdrId);
};
const actionTaken = (context, payload) => {
  const url = URL_UTILITY.getActionTakenUrl.replace(
    '{nfaHdrId}',
    payload.nfHdrId
  );
  return http.postApi(url, payload.actionTaken);
};
const getActionTake = (context, nfaHdrId) => {
  const url = URL_UTILITY.getNfaActionTakeUrl;
  return http.getApi(url + '/' + nfaHdrId);
};
const approvalReject = (context, payload) => {
  return http.postApi(URL_UTILITY.getApproveRejectUrl, payload);
};
const approvalRejectSingle = (context, payload) => {
  return http.postApi(URL_UTILITY.getApproveRejectSingleUrl, payload);
};
const getConsult = (context, payload) => {
  return http.postApi(URL_UTILITY.getWorkflowConsultUrl, payload);
};
const getNotificationDetailByStatus = (context, payload) => {
  const url = URL_UTILITY.getNotificationByStatusUrl
    .replace('{eventId}', payload.eventId)
    .replace('{status}', payload.status);
  return http.getApi(url);
};

const getWorkflowNotiCount = (context, payload) => {
  return http.getApi(URL_UTILITY.getWorkflowNotiCountUrl, payload);
};
const checkSavedPassword = () => {
  return http.getApi(URL_UTILITY.getOpenKMSavePasswordUrl);
};
const openKmAuthenticate = (context, payload) => {
  return http.postApi(URL_UTILITY.getOpenKmAuthenticationUrl, payload);
};
const openKmPartyPortalAuthenticate = () => {
  return http.postApi(URL_UTILITY.getOpenKmPartyPortalAuthenticateUrl);
};
const uploadOpenKmDoc = (context, payload) => {
  return http.uploadFile(URL_UTILITY.getOpenKmUploadDocUrl, payload);
};

const getOpenKmDocDtl = (context, payload) => {
  return http.getApi(URL_UTILITY.getOpenKmDocDtlUrl, payload);
};
const delteOpenKmDoc = (context, payload) => {
  return http.deleteApi(
    URL_UTILITY.getOpenKmDocDeleteUrl +
      '/' +
      payload.docDetailId +
      '?token=' +
      payload.token
  );
};
const previewOpenDoc = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getOpenKmDocDeleteUrl +
      '/preview/' +
      payload.docDetailId +
      '?token=' +
      payload.token
  );
};
const downloadOpenKmDoc = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getOpenKmDocDeleteUrl +
      '/download/' +
      payload.docDtlId +
      '?param=' +
      payload.openkmAuthToken
  );
};
const logoutOpenKm = (context, payload) => {
  return http.postApi(URL_UTILITY.getOpenKmLogoutUrl, payload);
};
const getReminderHitsory = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getWfReminderUrl + '/' + payload.menuId + '/' + payload.recordId
  );
};
const getFlexFieldAccounting = (context, payload) => {
  return http.getApi(URL_UTILITY.getFlexfieldAccountingUrl, payload);
};
const getNfaGridList = (context, payload) => {
  return http.getApi(URL_UTILITY.getNfaGridListUrl, payload);
};
const getNfaHdrDetails = (context, nfaHdrId) => {
  return http.getApi(URL_UTILITY.getNfaGridListUrl + '/' + nfaHdrId);
};
const getComparisonSheetDetails = (context, nfaHdrId) => {
  return http.getApi(
    URL_UTILITY.getNfaGridListUrl + '/' + nfaHdrId + '/comparison-sheet'
  );
};
const getVendorDetailsById = (context, itemDtlId) => {
  return http.getApi(
    URL_UTILITY.getNfaGridListUrl + '/vendors/' + itemDtlId + '/item-details'
  );
};
const getApprovedNfaPrice = (context, nfa_hdr_id) => {
  return http.getApi(
    URL_UTILITY.getNfaGridListUrl + '/' + nfa_hdr_id + '/last-approved'
  );
};
const getFetchItemDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getNfaGridListUrl +
      '/' +
      payload.nfa_hdr_id +
      '/fetch-vendor-items'
  );
};
const deleteItemsVendors = (context, payload) => {
  return http.putApi(
    URL_UTILITY.getNfaGridListUrl + '/' + payload.nfa_hdr_id,
    payload
  );
};
const approveForwardApproval = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getIntWorkflowHistoryUrl + '/approve-forward',
    payload
  );
};
const getNfaShortClose = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getNfaGridListUrl +
      `/short-close?nfa_hdr_id=${payload.nfa_hdr_id}`
  );
};
const addEditNfaShortClose = (context, payload) => {
  return http.postApi(URL_UTILITY.getNfaGridListUrl + '/short-close', payload);
};

const getPartyCustomerDunningDetails = (context, partyId) => {
  return http.getApi(
    URL_UTILITY.getCustomerSiteUrl + '/' + partyId + '/dunning'
  );
};

const editPartyCustomerDunningDetails = (context, payload) => {
  return http.postApi(URL_UTILITY.getCustomerSiteUrl + '/dunning', payload);
};

const excelUploadDetails = (context, excelImportId) => {
  return http.postApi(
    URL_UTILITY.getNfaGridListUrl + '/' + excelImportId + '/excel-upload'
  );
};

const getLeIdBasedErpLeId = (context, erpOrgId) => {
  return http.getApi(URL_UTILITY.getOrgStructureUrl + '/' + erpOrgId + '/le');
};

const getInternalContactDetails = (context, employeeId) => {
  return http.getApi(
    URL_UTILITY.getInternalContactDetailsUrl + '/' + employeeId
  );
};
const getPartyOnBoardingDetails = (context, payload) => {
  return http.getApi(URL_UTILITY.getPartyOnBoardingDetailsUrl, payload);
};
const getPartyOnBoardingDetailsById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPartyOnBoardingDetailsUrl +
      '/' +
      payload.party_req_id +
      '/user/' +
      payload.user_id
  );
};
const addEditPartyOnBoarding = (context, payload) => {
  return http.postApi(URL_UTILITY.getPartyOnBoardingDetailsUrl, payload);
};
const getVendorAddressDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getVendorAddressDetailsUrl + '/' + payload.party_req_id
  );
};
const getVendorContactDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getVendorContactDetailsUrl + '/' + payload.party_req_id
  );
};
const getVendorBankDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getVendorBankDetailsUrl + '/' + payload.party_req_id
  );
};
const getPreEvaluationSupplier = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPreEvaluationSupplierUrl + '/meta-data',
    payload
  );
};
const addEditPreEvaluationSupplier = (context, payload) => {
  return http.postApi(URL_UTILITY.getPreEvaluationSupplierUrl, payload);
};
const getVendorEvaluationDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPreEvaluationSupplierUrl +
      '/' +
      payload.party_req_id +
      '?type=' +
      payload.type
  );
};
const getVendorComplianceDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getVendorComplianceDetailsUrl + '/' + payload.party_req_id
  );
};
const getPartyAccDetails = (context, payload) => {
  return http.getApi(URL_UTILITY.getPartyAccDetailsUrl + '/' + payload.ou_id);
};
const addEditPartyAccDetails = (context, payload) => {
  return http.postApi(URL_UTILITY.addEditPartyAccDetailsUrl, payload);
};
const getPartyAccDetailsById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.addEditPartyAccDetailsUrl + '/party-req/' + payload.party_req_id
  );
};
const getAccountingDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getAccountingDetailsUrl +
      '?party_req_id=' +
      payload.party_req_id +
      '&le_id=' +
      payload.le_id +
      '&ou_id=' +
      payload.ou_id +
      '&vendor_type=' +
      payload.vendor_type
  );
};
const getAccountingDetailsFin = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getAccountingDetailsUrl +
      '-financial' +
      '?party_req_id=' +
      payload.party_req_id +
      '&le_id=' +
      payload.le_id +
      '&ou_id=' +
      payload.ou_id +
      '&vendor_type=' +
      payload.vendor_type
  );
};
const getAdditionalDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getAdditionalDetailsUrl + '/' + payload.party_req_id
  );
};
const checkPanVerify = (context, payload) => {
  return http.postApi(URL_UTILITY.checkPanVerifyUrl, payload);
};
const addPanVerifyDetails = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getPartyOnBoardingDetailsUrl + '/party-pan-verification',
    payload
  );
};
const getPanVerifyDetailsById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPartyOnBoardingDetailsUrl +
      '/party-pan-verification/' +
      payload.party_req_id
  );
};
const isVendorExist = (context, payload) => {
  return http.postApi(URL_UTILITY.isVendorExistUrl, payload);
};

const deleteVendor = (context, payload) => {
  return http.deleteApi(
    URL_UTILITY.getPartyOnBoardingDetailsUrl + '/' + payload.party_req_id
  );
};

const getAllDocumentsById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getAllDocumentsByIdUrl +
      '/' +
      payload.party_req_id +
      '/uploaded-doc-list'
  );
};

const getMenuIdForPartyOnBoarding = () => {
  return http.getApi(
    URL_UTILITY.getPartyOnBoardingDetailsUrl + '/workflow-menu'
  );
};

const resendEmailPartyonboarding = (context, payload) => {
  return http.getApi(
    URL_UTILITY.resendEmailPartyonboardingUrl + '/' + payload.party_req_id
  );
};

const setApprovalStatus = (context, appStatus) => {
  context.commit('setAppStatus', appStatus);
};

const addEditLeaseAgreementDetails = (context, payload) => {
  return http.postApi(URL_UTILITY.addEditLeaseAgrmntDtlsUrl, payload);
};
const getLeaseAgreementDetails = (context, payload) => {
  return http.getApi(URL_UTILITY.addEditLeaseAgrmntDtlsUrl, payload);
};
const getLeaseAgreementDetailsById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.addEditLeaseAgrmntDtlsUrl + '/' + payload.agreement_id
  );
};
const getRequestTemplateId = () => {
  return http.getApi(URL_UTILITY.getRequestTemplateIdUrl);
};
const getAgreementReqId = (context, payload) => {
  return http.getApi(
    URL_UTILITY.updateAgreementReqIdUrl +
      '?' +
      `request_id=${payload.request_id}` +
      '&' +
      `agreement_id=${payload.agreement_id}`
  );
};
// const getLeaseAgrmntRptPreview = (context, payload) => {
//   return http.getApi(
//     URL_UTILITY.previewLeaseAgrmntRptUrl + '/' + payload.request_id
//   );
// };,
const getExpenseDetails = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getExpenseReportUrl + '/get_expenses',
    payload
  );
};

const getExpenseDetailsById = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getExpenseReportUrl + '/expense_details',
    payload
  );
};
const getStateList = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getExpenseReportUrl + '/State_Value_set',
    payload
  );
};
const getCentreList = (context, payload) => {
  return http.getApi(URL_UTILITY.getExpenseReportUrl + '/Value_set', payload);
};
const getPeriodList = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getExpenseReportUrl + '/gl-expense-month-period',
    payload
  );
};
const getVendorInvoiceDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getValueSetUrl + '/vendor-invoice-entry',
    payload
  );
};
const getVendorInvoiceDetailsById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getValueSetUrl +
      '/vendor-invoices-entry/' +
      payload.vendor_inv_id,
    payload
  );
};
const getDynamicMenuId = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPartyOnBoardingDetailsUrl +
      '/workflow-menu/' +
      payload.event_name
  );
};
const createGrnInvoiceApproval = (context, payload) => {
  return http.postApi(URL_UTILITY.createGrnInvoiceApprovalUrl, payload);
};
const getVendorDetails = (context, payload) => {
  return http.postApi(URL_UTILITY.getValueSetUrl + '/vendor-id', payload);
};
const getExpenseMonth = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getValueSetUrl + '/expense-month-period-name',
    payload
  );
};
const getTdsSection = (context, payload) => {
  return http.getApi(URL_UTILITY.getValueSetUrl + '/tds-section', payload);
};
const getTdsRate = (context, payload) => {
  return http.getApi(URL_UTILITY.getValueSetUrl + '/tds-rate', payload);
};
const updateInvoiceDetailsFinance = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getValueSetUrl + '/finance-approval-line-update',
    payload
  );
};
const getTermsCondition = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getValueSetUrl + '/party-req-dis/' + payload.party_req_id
  );
};
const getValidateGlDate = (context, payload) => {
  return http.getApi(URL_UTILITY.getValueSetUrl + '/gl-date', payload);
};
const getGrnReceiptMappingData = (context, payload) => {
  return http.getApi(URL_UTILITY.getValueSetUrl + '/grn-reciept', payload);
};
const getGrnReceiptDetailsData = (context, payload) => {
  return http.getApi(URL_UTILITY.getValueSetUrl + '/grn-dtls', payload);
};
const validateCurrencyExchangeRate = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getValueSetUrl + '/exchange-rate-currency',
    payload
  );
};
const grnMappingForInvoiceApproval = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getValueSetUrl + '/grn-receipt-update',
    payload
  );
};
const addEditInvoiceDetailsFinance = (context, payload) => {
  return http.postApi(URL_UTILITY.addEditInvoiceDetailsFinanceUrl, payload);
};
const sendEmailVendor = (context, payload) => {
  return http.postApi(URL_UTILITY.getValueSetUrl + '/send-email', payload);
};
const getTotalTaxAmount = (context, payload) => {
  return http.getApi(URL_UTILITY.getValueSetUrl + '/invoice-line-sum', payload);
};
const panAadhaarVerify = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getValueSetUrl + '/pan-aadhaar-verify',
    payload
  );
};
const approveForwardInvoice = (context, payload) => {
  return http.postApi(URL_UTILITY.approveForwardInvoiceUrl, payload);
};
const getCostCentreDetails = (context, payload) => {
  return http.getApi(URL_UTILITY.getValueSetUrl + '/cost-centre', payload);
};
const addEditSplitLineData = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getValueSetUrl + '/manual-invoices-split-line',
    payload
  );
};
const getSplitLineDetailsById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getValueSetUrl +
      '/manual-invoices-split-line/' +
      payload.vendor_invoice_dtl_id
  );
};
const addEditSplitLineFinData = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getValueSetUrl + '/manual-invoices-split-line-fin',
    payload
  );
};
const getSplitLineFinDetailsById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getValueSetUrl +
      '/manual-invoices-split-line-fin/' +
      '?vendor_inv_dtl_id=' +
      payload.vendor_invoice_dtl_id
  );
};
const getOperatingUnitDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getValueSetUrl +
      '/manual-ou' +
      '?le_id=' +
      payload.le_id +
      '&ou_name=' +
      payload.ou_name
  );
};
const assignVendorInvoice = (context, payload) => {
  return http.postApi(URL_UTILITY.getValueSetUrl + '/assign-to-me', payload);
};
const getVendorSiteDetails = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getValueSetUrl + '/manual-vendor-site-name',
    payload
  );
};
const isCurrentDateInRange = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getValueSetUrl + '/po-line-data-ora',
    payload
  );
};
const removeCostCentreLine = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getValueSetUrl + '/cost-centre-delete',
    payload
  );
};
const validationInvoiceApproval = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getValueSetUrl + '/cost-centre-check',
    payload
  );
};
const addEditSplitLineWithPo = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getValueSetUrl + '/po-invoices-split-line',
    payload
  );
};
const getSplitLineWithPoById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getValueSetUrl +
      '/po-invoices-split-line' +
      '?vendor_inv_dtl_id=' +
      payload.vendor_invoice_dtl_id
  );
};
const getDistributionDetails = (context, payload) => {
  return http.getApi(URL_UTILITY.getValueSetUrl + '/distribution-set', payload);
};
const getDistributionSetDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getValueSetUrl + '/distribution-set-dtls',
    payload
  );
};
const addEditSplitLineFinWithPo = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getValueSetUrl + '/po-invoices-split-line-fin',
    payload
  );
};
const getSplitLineFinWithPoDetailsById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getValueSetUrl +
      '/po-invoices-split-line-fin' +
      '?vendor_inv_dtl_id=' +
      payload.vendor_invoice_dtl_id
  );
};
const deleteCostCentre = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getValueSetUrl + '/delete-cost-centre',
    payload
  );
};
const uploadDocFms = (context, payload) => {
  return http.postApi(URL_UTILITY.uploadDocumentUrl, payload);
};
const downloadCostCentreDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getValueSetUrl +
      '/cost-centre-download/' +
      payload.vendor_inv_id
  );
};
const deleteDistributionDetails = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getValueSetUrl + '/delete-distribution',
    payload
  );
};
const getPaymentTerms = (context, payload) => {
  return http.postApi(URL_UTILITY.getValueSetUrl + '/payment-terms', payload);
};
const getBackgroundVerificationData = (context, payload) => {
  return http.getApi(URL_UTILITY.getValueSetUrl + '/bgvc-grid', payload);
};
const getBackgroundVerificationDetailsData = (context, payload) => {
  return http.getApi(URL_UTILITY.getValueSetUrl + `/bgvc/${payload.bgVcHdrId}`);
};
const postBackgroundVerificationData = (context, payload) => {
  return http.postApi(URL_UTILITY.getValueSetUrl + '/bgvc', payload);
};
const getBgvcData = (context, payload) => {
  return http.getApi(URL_UTILITY.getValueSetUrl + '/bgvc-grid-data', payload);
};
const updateBgvcData = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getValueSetUrl +
      `/update-bgvc-grid?BGVC_HDR_ID=${payload.BGVC_HDR_ID}`
  );
};
const closeBgvc = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getValueSetUrl +
      `/close-bgvc?BGVC_HDR_ID=${payload.BGVC_HDR_ID}&Status=Closed`,
    payload
  );
};
const deleteDirectorDetails = (context, payload) => {
  return http.postApi(URL_UTILITY.getValueSetUrl + `/bgvc-dir-del`, payload);
};
const getAdvanceDataInInvoiceApproval = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getValueSetUrl +
      '/invoice-adv-dtls-ora', payload
  );
};
const postAdvanceDataInInvoiceApproval = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getValueSetUrl +
      '/invoice-adv-dtls', payload
  );
};
const getAdvancesAppliedDataInInvoiceApproval = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getValueSetUrl +
      `/invoice-adv-dtls/${payload.vendorInvId}`
  );
};
const deleteAdvancesDataInInvoiceApproval = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getValueSetUrl +
      `/invoice-adv-dtls-delete`, payload
  );
};
const getRealappPenindAmount = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getValueSetUrl +
      `/invoice-adv-realapp-pending`, payload
  );
};
export default {
  previewOpenDoc,
  getApprovedNfaPrice,
  getFetchItemDetails,
  getIntWorkflowList,
  getWfTabsDetails,
  addEditIntWorkflow,
  getWaiverData,
  getCodeComb,
  getHistory,
  getEventId,
  processWorkflow,
  getApprovalList,
  getSendForApprovalList,
  getWrkflowApproval,
  getWfDetailsById,
  actionTaken,
  approvalReject,
  getConsult,
  getNotificationDetailByStatus,
  getWorkflowNotiCount,
  checkSavedPassword,
  openKmAuthenticate,
  openKmPartyPortalAuthenticate,
  uploadOpenKmDoc,
  getOpenKmDocDtl,
  delteOpenKmDoc,
  downloadOpenKmDoc,
  logoutOpenKm,
  getReminderHitsory,
  getActionTake,
  getFlexFieldAccounting,
  getPaymentSeriesList,
  addEditPaymentSeriesList,
  getSkipSeriesList,
  addEditSkipSeriesList,
  getNfaGridList,
  getNfaHdrDetails,
  getVendorDetailsById,
  addEditSourcingApprovalForHeader,
  addEditSourcingApprovalForDetails,
  deleteItemsVendors,
  getComparisonSheetDetails,
  approveForwardApproval,
  getNfaShortClose,
  addEditNfaShortClose,
  getPartyCustomerDunningDetails,
  editPartyCustomerDunningDetails,
  excelUploadDetails,
  getLeIdBasedErpLeId,
  getInternalContactDetails,
  getPartyOnBoardingDetails,
  getPartyOnBoardingDetailsById,
  addEditPartyOnBoarding,
  getVendorAddressDetails,
  getVendorContactDetails,
  getVendorBankDetails,
  approvalRejectSingle,
  getPreEvaluationSupplier,
  addEditPreEvaluationSupplier,
  getVendorEvaluationDetails,
  getVendorComplianceDetails,
  getPartyAccDetails,
  addEditPartyAccDetails,
  getPartyAccDetailsById,
  getAccountingDetails,
  getAdditionalDetails,
  checkPanVerify,
  addPanVerifyDetails,
  getPanVerifyDetailsById,
  isVendorExist,
  setApprovalStatus,
  deleteVendor,
  getAllDocumentsById,
  getMenuIdForPartyOnBoarding,
  getAccountingDetailsFin,
  resendEmailPartyonboarding,
  addEditLeaseAgreementDetails,
  getLeaseAgreementDetails,
  getLeaseAgreementDetailsById,
  getRequestTemplateId,
  getAgreementReqId,
  getExpenseDetails,
  getExpenseDetailsById,
  getVendorInvoiceDetails,
  getVendorInvoiceDetailsById,
  getDynamicMenuId,
  createGrnInvoiceApproval,
  getVendorDetails,
  getExpenseMonth,
  getTdsSection,
  getTdsRate,
  updateInvoiceDetailsFinance,
  getTermsCondition,
  getStateList,
  getCentreList,
  getPeriodList,
  getValidateGlDate,
  getGrnReceiptMappingData,
  getGrnReceiptDetailsData,
  validateCurrencyExchangeRate,
  grnMappingForInvoiceApproval,
  addEditInvoiceDetailsFinance,
  sendEmailVendor,
  getTotalTaxAmount,
  panAadhaarVerify,
  approveForwardInvoice,
  getCostCentreDetails,
  addEditSplitLineData,
  getSplitLineDetailsById,
  getOperatingUnitDetails,
  assignVendorInvoice,
  addEditSplitLineFinData,
  getSplitLineFinDetailsById,
  getVendorSiteDetails,
  isCurrentDateInRange,
  removeCostCentreLine,
  validationInvoiceApproval,
  addEditSplitLineWithPo,
  getSplitLineWithPoById,
  getDistributionDetails,
  getDistributionSetDetails,
  addEditSplitLineFinWithPo,
  getSplitLineFinWithPoDetailsById,
  deleteCostCentre,
  uploadDocFms,
  downloadCostCentreDetails,
  deleteDistributionDetails,
  getPaymentTerms,
  getBackgroundVerificationData,
  getBackgroundVerificationDetailsData,
  postBackgroundVerificationData,
  getBgvcData,
  updateBgvcData,
  closeBgvc,
  deleteDirectorDetails,
  getAdvanceDataInInvoiceApproval,
  postAdvanceDataInInvoiceApproval,
  getAdvancesAppliedDataInInvoiceApproval,
  deleteAdvancesDataInInvoiceApproval,
  getRealappPenindAmount
  // getLeaseAgrmntRptPreview
};
