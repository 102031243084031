import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getLeaseProjectsList = (context, payload) => {
  const url = URL_UTILITY.getLeaseProjectsListUrl;
  return http.getApi(url, payload);
}

const addEditLeaseProject = (context, payload) => {
  const url = URL_UTILITY.addEditLeaseProjectUrl;
  return http.postApi(url, payload);
}
const getLeaseProjectContact =(context,payload) =>{
  const url = URL_UTILITY.leaseAdminUrl +'/lease-project-contact/'+ payload.prjId;
  return http.getApi(url); 
}
const postLeaseProjectContact =(context,payload) =>{
  const url = (URL_UTILITY.leaseAdminUrl +'/lease-project-contact');
  return http.postApi(url, payload); 
}

export default {
  getLeaseProjectsList,
  addEditLeaseProject,
  getLeaseProjectContact,
  postLeaseProjectContact
};
