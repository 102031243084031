import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getMeterAccessDtls = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFmsAdmindUrl + '/fms_user-meter-reading-uax',
    payload
  );
};
const getMeterAccessDtlsById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFmsAdmindUrl + '/fms_user-meter-reading-uax/' + payload
  );
};
const addEditMeterAccessData = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFmsAdmindUrl +
      '/fms_user-meter-reading-uax/' +
      payload.user_id,
    payload.payloadData
  );
};
const getEnergyDataInput = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFmsAdmindUrl + '/fms-meter-energy-input',
    payload
  );
};
const getEnergyDataInputById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFmsAdmindUrl + '/fms-meter-energy-input/energy_batch_hdr_id',
    payload
  );
};
const getEnergyDataInputHdrDtlsById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFmsAdmindUrl +
      '/fms-meter-energy-input/batch-hdr-id/' +
      payload.energyBatchHdrId
  );
};
const excelUploadEnergyDataInput = (context, excelImportId) => {
  return http.postApi(
    URL_UTILITY.getFmsAdmindUrl +
      '/fms-meter-energy-input/upload-excel/' +
      excelImportId +
      '/excelImportHdrId'
  );
};
const excelDownloadEnergyInputData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFmsAdmindUrl + '/fms-meter-energy-input/excel-download',
    payload
  );
};
const updateEnergyInputData = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFmsAdmindUrl + '/fms-meter-energy-input/edit-energy-dtls',
    payload
  );
};
const refreshEnergyInputData = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFmsAdmindUrl +
      '/fms-meter-energy-input/refresh/' +
      payload.energyBatchHdrId
  );
};
export default {
  getMeterAccessDtls,
  getMeterAccessDtlsById,
  addEditMeterAccessData,
  getEnergyDataInput,
  getEnergyDataInputById,
  getEnergyDataInputHdrDtlsById,
  excelUploadEnergyDataInput,
  excelDownloadEnergyInputData,
  updateEnergyInputData,
  refreshEnergyInputData
};
