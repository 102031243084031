import http from '../../app/services/httpServices';
import URL_UTILITY from '../../app/utility/url.utility';

const getFcBillingData = (context, payload) => {
  return http.getApi(URL_UTILITY.getFcBillingUrl + '/get-cost-center', payload);
};
const generateCost = (context, payload) => {
  return http.postApi(URL_UTILITY.getFcBillingUrl + '/submit-request', payload);
};
const getGenerateBillData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFcBillingUrl + '/get-fcbill-detail',
    payload
  );
};
const getCustomersData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFcBillingUrl + '/get-fc-customers',
    payload
  );
};
const updateGenerateBillData = (context, payload) => {
  return http.postApi(URL_UTILITY.getFcBillingUrl + '/fcbill-detail', payload);
};
const getViewInvDtlsData = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFcBillingUrl + '/fcbill-view-invoice',
    payload
  );
};
const getTaxCategoryData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFcBillingUrl + '/get-fc-taxcategory',
    payload
  );
};
const getTrxTypeData = (context, payload) => {
  return http.getApi(URL_UTILITY.getFcBillingUrl + '/get-fc-trxtype', payload);
};

const postToOracleInvData = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFcBillingUrl + '/fc-post-to-oracle',
    payload
  );
};

const getReqStatus = (context, payload) => {
  return http.getApi(URL_UTILITY.getFcBillingUrl + '/get-request-status', payload);
};

export default {
  getFcBillingData,
  generateCost,
  getGenerateBillData,
  getCustomersData,
  updateGenerateBillData,
  getViewInvDtlsData,
  getTaxCategoryData,
  getTrxTypeData,
  postToOracleInvData,
  getReqStatus
};
