import http from '@/app/services/httpServices';
import URL_UTILITY from '@/app/utility/url.utility';

const getAllAssets = (context, payload) => {
  const url =
    URL_UTILITY.getAssetsUrl +
    `?_page=${payload.pagination._page}&_limit=${payload.pagination._limit}`;
  return http.postApi(url, payload.params);
};

const getTransferAssets = (context, payload) => {
  return http.getApi(URL_UTILITY.getTransferAssetsUrl, payload);
};

const getTransferDetailsById = (context, payload) => {
  return http.getApi(URL_UTILITY.getTransferAssetsUrl + '/' + payload.trfHdrId);
};

const getAllAssetsForTransfer = (context, payload) => {
  const url =
    URL_UTILITY.getTransferAssetsUrl +
    `/${payload.trfHdrId}/assets?_page=${payload._page}&_limit=${payload._limit}`;
  return http.postApi(url, payload.searchParams);
};

const getTransferredAssets = (context, payload) => {
  const url =
    URL_UTILITY.getTransferAssetsUrl +
    `/${payload.trfHdrId}/transferred-assets`;
  return http.getApi(url, payload.pagination);
};

const getReceivedAssetsBatchList = (context, payload) => {
  return http.getApi(URL_UTILITY.getReceiveAssetBatchUrl, payload);
};

const getAllAssetsForReceiveBatch = (context, payload) => {
  const url =
    URL_UTILITY.getReceiveAssetBatchUrl + `/${payload.trfHdrId}/receive-assets`;
  return http.getApi(url, payload.pagination);
};
const getLocationBasedGstn = (context, payload) => {
  const url = URL_UTILITY.getLocationBasedGstnUrl.replace(
    '{locId}',
    payload.locId
  );
  return http.getApi(url + `?le_id=${payload.leId}`);
};

const createTransferBatch = (context, payload) => {
  return http.postApi(URL_UTILITY.getTransferAssetsUrl, payload);
};

const addTransferAssetsDetails = (context, payload) => {
  const url =
    URL_UTILITY.getTransferAssetsUrl +
    `/${payload.trfHdrId}/transferred-assets`;
  return http.postApi(url, payload.assetDetails);
};

const deleteTransferAssetsDetails = (context, payload) => {
  const url =
    URL_UTILITY.getTransferAssetsUrl +
    `/${payload.trfHdrId}/transferred-assets`;
  return http.putApi(url, payload.assetDetails);
};

const getSyncTransactionAsset = (context, payload) => {
  return http.getApi(URL_UTILITY.getSyncTransactionAssetListDataUrl, payload);
};

const postSyncTransactionProcessAsset = (context, payload) => {
  return http.postApi(
    URL_UTILITY.postSyncTransactionProcessAssetListDataUrl,
    payload
  );
};

const saveAssetTaxCategory = (context, payload) => {
  const url = URL_UTILITY.assetTaxDetailsUrl + '/save-tax';
  return http.postApi(url, payload);
};

const calculateAssetTaxValue = (context, payload) => {
  const url = URL_UTILITY.assetTaxDetailsUrl + '/calculate-tax';
  return http.postApi(url, payload);
};

const saveAssetTaxCatByTrnsfrBatchId = (context, payload) => {
  const url =
    URL_UTILITY.getTransferAssetsUrl +
    `/${payload.trfHdrId}/transferred-assets/saveTaxCat`;
  return http.postApi(url, payload.assetDetails);
};

const processTransferAssets = (context, payload) => {
  const url =
    URL_UTILITY.getTransferAssetsUrl + `/${payload.trx_id}/transfer-process`;
  return http.postApi(url, payload);
};

const getTransferAssetTmpDetails = (context, payload) => {
  return http.getApi(URL_UTILITY.getTransferAssetTmpDetailsDataUrl, payload);
};

const addUploadAssetExcelList = (context, payload) => {
  const url = URL_UTILITY.addUploadExcelAssetListUrl;
  return http.postApi(
    url +
      '?excel_import_hdr_id=' +
      payload.excel_import_hdr_id +
      '&menu_id=' +
      payload.menu_id +
      '&sub_menu_id=' +
      payload.sub_menu_id
  );
};
const postUpdateLocationProcess = (context, payload) => {
  return http.postApi(URL_UTILITY.postUpdateLocationProcessDataUrl, payload);
};

const getRecieveAssetBatchHdr = (context, trfHdrId) => {
  const url =
    URL_UTILITY.getReceiveAssetBatchUrl + `/${trfHdrId}/receive-assets-hdr`;
  return http.getApi(url);
};

const getPrintTransferAsset = () => {
  return http.getApi(URL_UTILITY.getPrintTransferAssetUrl);
};
const postRecieveAsset = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getReceiveAssetBatchUrl +
      `/${payload.trx_id}/receive-assets-in-oracle`,
    payload
  );
};
const getProcessStatusAsset = (context, trfHdrId) => {
  return http.getApi(
    URL_UTILITY.getTransferAssetsUrl + '/' + trfHdrId + '/request-num'
  );
};

const getCreatePvJobBatchList = (context, payload) => {
  return http.getApi(URL_UTILITY.getCreatePvJobBatchUrl, payload);
};
const getProcessStatus = (context, trfHdrId) => {
  return http.getApi(
    URL_UTILITY.getTransferAssetsUrl + '/' + trfHdrId + '/trf-process-status'
  );
};
const addBulkReceiveAsset = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getReceiveAssetBatchUrl + '/bulk-receive-assets-in-oracle',
    payload
  );
};
const addEditCreatePvJob = (context, payload) => {
  return http.postApi(URL_UTILITY.getCreatePvJobBatchUrl, payload);
};
const getIrnProcessById = (context, trfHdrId) => {
  return http.getApi(
    URL_UTILITY.getTransferAssetsUrl + '/' + trfHdrId + '/trf-irn-status'
  );
};
// const getIrnRequest = () => {
//   return http.getApi(URL_UTILITY.getIrnRequestUrl);
// };

const getIrnRequest = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFaLocationUrl +
      '/transfer-batches/' +
      payload.trfHdrId +
      '/irn-regenerate'
  );
};

const updateHsnCodeById = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getTransferAssetsUrl +
      '/' +
      payload.trf_hdr_id +
      '/hsn-code-update',
    payload
  );
};
const getCreateBatchDetailsById = (context, payload) => {
  const url =
    URL_UTILITY.getCreatePvJobBatchUrl +
    `/${payload.pvHdrId}/assets?_page=${payload._page}&_limit=${payload._limit}`;
  return http.postApi(url, payload.searchParams);
};
const getAddressDetailsById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + '/address/challan',
    payload
  );
};
const getFaLocation = (context, payload) => {
  return http.getApi(URL_UTILITY.getFaLocationUrl + '/fa-locations', payload);
};
const getDelChallanDtls = (context, payload) => {
  return http.getApi(URL_UTILITY.getFaLocationUrl + '/del-challan', payload);
};
const getDelChallandtlsById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl +
      '/challan' +
      '?challan_hdr_id=' +
      payload.del_challan_id
  );
};
const addEditDelChallanDtls = (context, payload) => {
  return http.postApi(URL_UTILITY.getFaLocationUrl + '/challan', payload);
};
const getTemplateDetails = (context, payload) => {
  return http.getApi(URL_UTILITY.getTemplateUrl, payload);
};

const getInvoiceNumber = (context, payload) => {
  return http.getApi(URL_UTILITY.getFaLocationUrl + '/get-invoices', payload);
};

const getEwayBill = (context, payload) => {
  return http.getApi(URL_UTILITY.getFaLocationUrl + '/get-ewaybills', payload);
};

const addEditEwayBill = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFaLocationUrl + '/add-edit-ewaybills',

    payload
  );
};

const getEwayBillDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl +
      '/get-ewaybills/' +
      payload.invoice_id +
      '/' +
      payload.source
  );
};

const generateEwayBill = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFaLocationUrl + '/process-ewaybills/' + payload
  );
};

const getGrnAsset = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + '/uncapitalized-assets',
    payload
  );
};

const addEditHrLocation = (context, payload) => {
  return http.putApi(URL_UTILITY.getFaLocationUrl + '/fa-locations', payload);
};

const getProcessTransfer = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getProcessTransferAssetUrl + '/' + payload.trfHdrId
  );
};

const cancelEwayBill = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFaLocationUrl + '/cancel-ewaybills',
    payload
  );
};
// const getGrnAssetGridData = (context, payload) => {
//   return http.postApi(URL_UTILITY.getGrnAssetUrl, payload)
// }
const getGrnAssetGridData = (context, payload) => {
  const url =
    URL_UTILITY.getGrnAssetUrl +
    `?_page=${payload.pagination._page}&_limit=${payload.pagination._limit}`;
  return http.postApi(url, payload.params);
};
const addLocator = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFaLocationUrl + '/fa-locator/' + payload[0].fa_location_id,
    payload
  );
};

const getLocator = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + '/fa-locator/' + payload.fa_location_id
  );
};

const addFaLocation = (context, payload) => {
  return http.postApi(URL_UTILITY.getFaLocationUrl + '/fa-locations', payload);
};
const updateGrnAsset = (context, payload) => {
  return http.postApi(URL_UTILITY.getGrnAssetUrl + '/create', payload);
};
const alreadyCreatedQty = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getGrnAssetUrl + '/generated-asset-quantity',
    payload
  );
};
const addEditGrnAsset = (context, payload) => {
  return http.putApi(
    URL_UTILITY.getGrnAssetUrl + '/' + payload.asset_id,
    payload
  );
};
const generateAsset = (context, payload) => {
  return http.getApi(URL_UTILITY.getGrnAssetUrl + '/generated-asset', payload);
};
const getCopyAssetMasterData = (context, payload) => {
  return http.getApi(URL_UTILITY.getGrnAssetUrl, payload);
};

const ewayBillReport = (context, payload) => {
  return http.postApi(
    URL_UTILITY.generateRequestUrl + '/generate-eway-bill/submit-requests',
    payload
  );
};

const getAssetTags = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + '/asset-master-tag/' + payload.asset_id,
    payload
  );
};

const getAssetItemById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + '/asset-item/' + payload.item_id,
    payload
  );
};
const getAssetMasterItem = (context, payload) => {
  return http.getApi(URL_UTILITY.getFaLocationUrl + '/asset-item/', payload);
};

const postAssetMasterItem = (context, payload) => {
  return http.postApi(URL_UTILITY.getFaLocationUrl + '/asset-item/', payload);
};

const putAssetMasterItem = (context, payload) => {
  return http.putApi(
    URL_UTILITY.getFaLocationUrl + '/asset-item/' + payload.item_id,
    payload
  );
};

const getAssetMasterTag = (context, payload) => {
  return http.getApi(URL_UTILITY.getFaLocationUrl + '/asset-tag/', payload);
};

const postAssetMasterTag = (context, payload) => {
  return http.postApi(URL_UTILITY.getFaLocationUrl + '/asset-tag/', payload);
};

const putAssetMasterTag = (context, payload) => {
  return http.putApi(
    URL_UTILITY.getFaLocationUrl + '/asset-tag/' + payload.tag_id,
    payload
  );
};

const getAssetItem = (context, payload) => {
  return http.getApi(URL_UTILITY.getFaLocationUrl + '/asset-item/', payload);
};

const getAssetLocGridData = (context, payload) => {
  return http.getApi(URL_UTILITY.getFaLocationUrl + '/fa-locator', payload);
};

const getLocDetailsById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl +
      '/fa-locator-parent/' +
      payload.pathVariable.faLocId,
    payload.params
  );
};
const getAssetExcelData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + '/getExcelTempData-grnAsset',
    payload
  );
};
const getLocCodeCombGridData = (context, payload) => {
  return http.getApi(URL_UTILITY.getFaLocationUrl + '/locator-comb', payload);
};

const getPoNumList = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + '/uncapitalized-assets-ponumber',
    payload
  );
};

const postAddEditAudit = (context, payload) => {
  return http.postApi(URL_UTILITY.getFaLocationUrl + '/asset-audit', payload);
};

const getAuditData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + '/asset-audit/' + payload.locId,
    payload.queryParams
  );
};

const getAddEditAuditData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + '/asset-locator-dtl/' + payload.locId
  );
};

const getAuditHeaderDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + '/asset-audit-dtls/' + payload.hdrId
  );
};

const postAddEditBarcodes = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFaLocationUrl + '/asset-audit-line',
    payload
  );
};

const getBinDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl +
      '/asset-audit-bin-combination' +
      '?hdr_id=' +
      payload.hdr_id +
      '&bin_combination=' +
      payload.bin_combination
  );
};

const getAssetDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + '/asset-audit-dtl-bar-code/' + payload.dtlId,
    payload.queryParams
  );
};

const postAuditScanDetails = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFaLocationUrl + '/asset-audit-scan',
    payload
  );
};

const postAddEditConsumables = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFaLocationUrl + '/asset-audit-line-consumable',
    payload
  );
};

const getConsumableAssetDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl +
      '/asset-consumable-dtl/' +
      payload.auditHdrId +
      '/' +
      payload.auditDtlId
  );
};

const getAssetPutAwayDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl +
      '/asset-put-away-dtls/' +
      payload.params.hrLocId,
    payload.queryParams
  );
};

const postAssetPutAwayDetails = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFaLocationUrl + '/asset-put-away-dtls/',
    payload
  );
};

const getAllocationGridData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl +
      '/asset-rack-shelf-bin-dtl/' +
      payload.params.faLocId,
    payload.queryParams
  );
};

const getDashboardData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + '/asset-dashboard/' + payload.hrLocId
  );
};

const deleteItems = (context, payload) => {
  return http.deleteApi(
    URL_UTILITY.getFaLocationUrl +
      '/asset-audit-delete-bar-code/' +
      payload.dtlId
  );
};

const getDashboardAssetStatusDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl +
      '/asset-dashboard-dtl/' +
      payload.params.hrLocId,
    payload.queryParams
  );
};

const postAssetStatusDetails = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFaLocationUrl + '/asset-audit-status/',
    payload
  );
};

const getAssetHistoryDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl +
      '/asset-audit-status-history/' +
      payload.assetNo,
    payload
  );
};

const getAssetViewDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl +
      '/asset-master/generated-asset/' +
      payload.assetNum
  );
};

const getAssetLiqDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + '/asset-liq/' + payload.params.hrLocId,
    payload.queryParams
  );
};

const postAssetLiqDetails = (context, payload) => {
  return http.postApi(URL_UTILITY.getFaLocationUrl + '/asset-liq/', payload);
};

const getSavedAssetLiqDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + '/asset-liq-batch/' + payload.params.batchId,
    payload.queryParams
  );
};

const deleteSavedLiqAsset = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFaLocationUrl + '/asset-audit-delete-liq/' + payload.doc_num
  );
};

const getAssetLiqBatchDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + '/asset-liq-batch/',
    payload
  );
};

const postConsumablesDetails = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFaLocationUrl +
      `/transfer-batches/${payload.trfHdrId}/consumable-assets`,
    payload
  );
};

const getConsumablesDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl +
      `/transfer-batches/${payload.trfHdrId}/consumable-assets`,
    payload
  );
};

const getRMAreplacement = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl +
      `/asset-rma/${payload.hrLocId}` +
      '?assetNo=' +
      payload.assetNo +
      '&serialNo=' +
      payload.serialNo,
    payload.pagination
  );
};

const closeRmaReplacement = (context, payload) => {
  return http.patchApi(
    URL_UTILITY.getFaLocationUrl + '/asset-rma/update-rma-status',
    payload
  );
};

const processTransferRmaReplacement = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFaLocationUrl +
      `/asset-rma/${payload.trx_id}/rma-replacement-process`,
    payload
  );
};

const processTransferRma = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFaLocationUrl + `/asset-rma/${payload.trx_id}/rma-process`,
    payload
  );
};

const addRmaReplacement = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFaLocationUrl +
      `/asset-rma/${payload.trx_id}/rma-replacement-assets`,
    payload
  );
};
const getRmaReplacement = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl +
      `/asset-rma/${payload.trx_id}/rma-replacement-assets`
  );
};

const getRealappAssetsDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + '/asset-matching/' + payload.hrLocId,
    payload
  );
};

const getGlobalAssets = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + '/asset-master-search/',
    payload.queryParams
  );
};

const printLabel = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + `/generate-shipment-label/${payload.waybill}`
  );
};

const createShipment = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFaLocationUrl + `/create-shipment/${payload.trfHdrId}`,
    payload
  );
};

const assetDepreciation = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFaLocationUrl + `/asset-depreciation-cost`,
    payload
  );
};

const createPickRequest = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFaLocationUrl + `/create-pickup-request/${payload.trfHdrId}`,
    { pickup_time: payload.pickup_time }
  );
};

const getShipmentTrackingDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + `/shipment-tracking/${payload.wayBillNo}`
  );
};

const getLogPhyAssetMatchData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + '/asset-match-testing/' + payload.faLoc,
    payload
  );
};

const postLogPhyAssetMatchData = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFaLocationUrl + '/asset-audit-matching-req',
    payload
  );
};

const getPutawayRackShelfBin = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl +
      '/asset-put-away-rack-shelf-bin-dtl/' +
      payload.locId
  );
};

const postItRecieveAsset = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFaLocationUrl +
      '/receive-batches/partially-receive-assets-in-oracle',
    payload
  );
};

const rejectBatch = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFaLocationUrl +
      `/transfer-batches/${payload.trf_hdr_id}/reject`,
    payload
  );
};

const getAuditHeader = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + '/asset-audit-scan/' + payload.hdrId
  );
};

const assetInboundExcelUpload = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFaLocationUrl + '/asset-master/excel-upload',
    payload
  );
};

const getOutboundHeader = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + `/transfer-batches/${payload.trf_hdr_id}`
  );
};

const assetAuditDataPoints = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + '/asset-match-result/' + payload.auditHdrId
  );
};

const saveConsumablesTaxCatByTrnsfrBatchId = (context, payload) => {
  const url =
    URL_UTILITY.getFaLocationUrl +
    `/consumable-asset/${payload.trfHdrId}/Consumable/saveTaxCat`;
  return http.postApi(url, payload.consumableDetails);
};

const getOracleAssetsDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + '/asset-matching/oracle/',
    payload
  );
};

const getRemedyMenuId = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getAuditRemedyMenuId +
      '/menus/menu-name?menu_name=' +
      payload.remedyName
  );
};

const postAuditRemedy = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFaLocationUrl + '/asset-audit-remedy',
    payload
  );
};

const getRemedyHeader = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl +
      '/asset-audit-rememdy-audithdr/' +
      payload.auditHdrId,
    payload.params
  );
};

const getRemedyBatchDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + '/asset-audit-rememdy-DTL/' + payload.BatchID
  );
};

const getRemedyDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + '/asset-audit-rememdy/' + payload.BatchID
  );
};

const scrapLiquidationBatch = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFaLocationUrl + '/asset-liq-batch-scrap',
    payload
  );
};

const scrapRemedyBatch = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFaLocationUrl + '/asset-audit-remedy-batch-scrap',
    payload
  );
};

const getLocationAccessFlag = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl +
      '/user-mapped-Location?hr_location_id=' +
      payload.hrLocId
  );
};

const closeAuditBatch = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFaLocationUrl + '/audit-close/' + payload.auditHdrId
  );
};

const saveProcessTransferRmaReplacement = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFaLocationUrl +
      `/asset-rma/${payload.trx_id}/rma-replacement`,
    payload
  );
};

const getMatchedOracleRealappAssets = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + '/asset-oracle-id-dtl/' + payload.hrLocId,
    payload.queryParams
  );
};

const postManualAssetsMatching = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFaLocationUrl + '/manual-asset-matching',
    payload
  );
};

const getLogicalAssets = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + `/locator-dtl-${payload.type}`,
    payload
  );
};

const getAuditScrapedData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl +
      '/asset-match-testing-data/' +
      payload.audit_hdr_id
  );
};

const getRemedyBatches = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl +
      '/asset-audit-rememdy-withoutpaginated/' +
      payload.auditHdrId
  );
};

const getUserAssets = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + '/get-user-asset-detail/',
    payload
  );
};

const getEmpList = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + '/it-asset-employee/',
    payload
  );
};

const getUserLocationData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + '/get-user-location_mapping/',
    payload
  );
};

const postUserLocationMapping = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFaLocationUrl + '/user-location-mapping',
    payload
  );
};

const getUserLocationMappedData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + '/location-user-id/' + payload.userId
  );
};

const deleteAllLocationsData = (context, payload) => {
  return http.deleteApi(
    URL_UTILITY.getFaLocationUrl + '/delete-all-location/' + payload.userId
  );
};

const deleteSinglelLocationsData = (context, payload) => {
  return http.deleteApi(
    URL_UTILITY.getFaLocationUrl +
      '/delete-location/' +
      payload.userId +
      '/' +
      payload.locationId
  );
};

const getLoginHistoryData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + '/get-device-info/' + payload.serial_no
  );
};

const getLoginHistoryShowAllData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFaLocationUrl + '/get-device-info-hist/' + payload.serial_no,
    payload.pagination
  );
};

const postDamageTypeReceivingEnd = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFaLocationUrl + '/receive-batches'+'/type-of-defect/'+payload.trfHdrId,
    payload.assetDetails
  );
};

const getUserDetails =(context, payload) => {
  return http.getApi(URL_UTILITY.getFaLocationUrl + "/get-po-status" ,payload);
};

const postAddAssetToExistingRemedy = (context, payload) => {
  return http.postApi(URL_UTILITY.getFaLocationUrl + '/add-asset-remedy', payload);
};

export default {
  getAllAssets,
  getTransferAssets,
  getAllAssetsForTransfer,
  getTransferredAssets,
  getReceivedAssetsBatchList,
  getAllAssetsForReceiveBatch,
  getLocationBasedGstn,
  createTransferBatch,
  addTransferAssetsDetails,
  deleteTransferAssetsDetails,
  getSyncTransactionAsset,
  postSyncTransactionProcessAsset,
  saveAssetTaxCategory,
  calculateAssetTaxValue,
  saveAssetTaxCatByTrnsfrBatchId,
  processTransferAssets,
  getTransferAssetTmpDetails,
  addUploadAssetExcelList,
  postUpdateLocationProcess,
  getRecieveAssetBatchHdr,
  getPrintTransferAsset,
  postRecieveAsset,
  getProcessStatusAsset,
  getProcessStatus,
  getCreatePvJobBatchList,
  addBulkReceiveAsset,
  addEditCreatePvJob,
  getIrnProcessById,
  getIrnRequest,
  getAddressDetailsById,
  updateHsnCodeById,
  getCreateBatchDetailsById,
  getTransferDetailsById,
  getFaLocation,
  getDelChallanDtls,
  getDelChallandtlsById,
  addEditDelChallanDtls,
  getTemplateDetails,
  getInvoiceNumber,
  getEwayBill,
  addEditEwayBill,
  getEwayBillDetails,
  generateEwayBill,
  getGrnAsset,
  getProcessTransfer,
  addEditHrLocation,
  cancelEwayBill,
  getGrnAssetGridData,
  addLocator,
  getLocator,
  addFaLocation,
  updateGrnAsset,
  alreadyCreatedQty,
  addEditGrnAsset,
  generateAsset,
  getCopyAssetMasterData,
  ewayBillReport,
  getAssetTags,
  getAssetItemById,
  getAssetMasterItem,
  postAssetMasterItem,
  putAssetMasterItem,
  getAssetMasterTag,
  postAssetMasterTag,
  putAssetMasterTag,
  getAssetItem,
  getAssetLocGridData,
  getLocDetailsById,
  getAssetExcelData,
  getLocCodeCombGridData,
  getPoNumList,
  postAddEditAudit,
  getAuditData,
  getAddEditAuditData,
  getAuditHeaderDetails,
  postAddEditBarcodes,
  getBinDetails,
  getAssetDetails,
  postAuditScanDetails,
  postAddEditConsumables,
  getConsumableAssetDetails,
  getAssetPutAwayDetails,
  postAssetPutAwayDetails,
  getAllocationGridData,
  getDashboardData,
  deleteItems,
  getDashboardAssetStatusDetails,
  postAssetStatusDetails,
  getAssetHistoryDetails,
  getAssetViewDetails,
  getAssetLiqDetails,
  postAssetLiqDetails,
  getSavedAssetLiqDetails,
  deleteSavedLiqAsset,
  getAssetLiqBatchDetails,
  postConsumablesDetails,
  getConsumablesDetails,
  getRMAreplacement,
  closeRmaReplacement,
  processTransferRmaReplacement,
  processTransferRma,
  addRmaReplacement,
  getRmaReplacement,
  getRealappAssetsDetails,
  getGlobalAssets,
  printLabel,
  createShipment,
  assetDepreciation,
  createPickRequest,
  getShipmentTrackingDetails,
  getLogPhyAssetMatchData,
  postLogPhyAssetMatchData,
  getPutawayRackShelfBin,
  postItRecieveAsset,
  rejectBatch,
  getAuditHeader,
  assetInboundExcelUpload,
  getOutboundHeader,
  assetAuditDataPoints,
  saveConsumablesTaxCatByTrnsfrBatchId,
  getOracleAssetsDetails,
  getRemedyMenuId,
  postAuditRemedy,
  getRemedyHeader,
  getRemedyBatchDetails,
  getRemedyDetails,
  scrapLiquidationBatch,
  scrapRemedyBatch,
  getLocationAccessFlag,
  closeAuditBatch,
  saveProcessTransferRmaReplacement,
  getMatchedOracleRealappAssets,
  postManualAssetsMatching,
  getLogicalAssets,
  getAuditScrapedData,
  getRemedyBatches,
  getUserAssets,
  getEmpList,
  getUserLocationData,
  postUserLocationMapping,
  getUserLocationMappedData,
  deleteAllLocationsData,
  deleteSinglelLocationsData,
  getLoginHistoryData,
  getLoginHistoryShowAllData,
  postDamageTypeReceivingEnd,
  getUserDetails,
  postAddAssetToExistingRemedy,
};
